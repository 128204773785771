// frontend\node_modules\@angular\material\slider\_slider-theme.scss
@import '~@angular/material/slider/_slider-theme.scss';

/* You can add global styles to this file, and also import other style files */
.navbar-light .navbar-brand .logo {
    background-image: url(/assets/img/logo-light.png);
    width: 300px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;

}

// @media (min-width: 992px) {

.sticky-top.navbar-light .navbar-brand .logo {
    background-image: url(/assets/img/logo-dark.png);
    width: 300px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
}

// }

@media (max-width: 992px) {
    .navbar-light .navbar-brand .logo {
        background-image: url(/assets/img/logo-dark.png);
    }
}

.bg-gradient-1 {
    background: #FFF;
}

// html,
// body {
//     height: 100%;
// }

// body {
//     margin: 0;
//     font-family: Roboto, "Helvetica Neue", sans-serif;
// }
.mat-slider-ticks-container {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.mat-slider-thumb {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
}

.mat-mdc-slider .mdc-slider__track--inactive {
    background: linear-gradient(to bottom right, var(--primary), var(--secondary));
    opacity: .24;
}

.mat-mdc-slider .mdc-slider__track--active_fill {
    border-color: linear-gradient(to bottom right, var(--primary), var(--secondary));
}